
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "projectName": "forerunner",
  "projectVersion": "1.73.2",
  "isDev": false,
  "noScripts": false,
  "nuxt": {
    "buildId": "090a0b54-32ff-4d70-bc38-ea5d2b0a653d"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/apzfront/forerunner-next/packages/base-nuxt-layer/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
